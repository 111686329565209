import React, { useEffect } from 'react';
import Typography from '../typography';
import { FlexBox } from '../common/wrappers';
import {
  getSampleOrderData,
  setSelectedProduct,
  setTotalCost,
} from '../../store/slices/sampleOrderSlice';
import { useAppSelector } from '../../store/hooks';
import { ColoredCircle } from '../common/elements';
import styled, { css } from 'styled-components';
import { colors } from '../../assets/colors';
import { DeleteIcon } from '../../assets/icons';
import { useDispatch } from 'react-redux';
import Input from '../inputs';
import Button from '../common/button';
import { useGetShippingCostMutation } from '../../store/services/orders';
import SmallLoader from '../common/small-loader';
import { generateSampleOrderShippingCostPayload } from './utils';
import CustomToolTip from '../custom-tooltip';

interface Props {
  setRecalculateShipping: (value: boolean) => void;
  recalculateShipping: boolean;
}
const Cart: React.FC<Props> = ({
  setRecalculateShipping,
  recalculateShipping,
}) => {
  const [getShippingCost, { isLoading }] = useGetShippingCostMutation();
  const { currentStep, selectedProducts, totalCost, outOfStockItems } =
    useAppSelector(getSampleOrderData);
  const dispatch = useDispatch();

  const handleInputChange = (
    productId: string,
    productColorID: number,
    variantId: string,
    newValue: number,
  ) => {
    setRecalculateShipping(true);
    const updatedProducts = selectedProducts.map((item) => {
      if (
        item.productId === productId &&
        item.productColorID === productColorID &&
        item.variantId === variantId
      ) {
        return {
          ...item,
          quantity: isNaN(newValue) ? 0 : Math.min(newValue, 99),
        };
      }
      return item;
    });
    dispatch(setSelectedProduct(updatedProducts));
  };

  const handleDelete = (printSKUId: string, productId: string) => {
    setRecalculateShipping(true);
    const updatedProducts = selectedProducts.filter(
      (product) =>
        !(product.printSKUId === printSKUId && product.productId === productId),
    );
    dispatch(setSelectedProduct(updatedProducts));
  };

  useEffect(() => {
    const totalPrice = selectedProducts.reduce((total, product) => {
      return product.cost * product.quantity + total;

    }, 0);

    dispatch(
      setTotalCost({ shippingCost: 0, totalProductionCost: totalPrice }),
    );
  }, [dispatch, selectedProducts]);

  const calculateProductPrice = (variantId: string, quantity: number) => {
    const variant = selectedProducts.find(
      (product) => product.variantId === variantId,
    );
    if (variant) {
      const totalProductPrice = variant.cost * quantity;

      return totalProductPrice.toFixed(2);
    }
    return '---';
  };

  const updateProductQuantity = (
    productId: string,
    productColorID: number,
    variantId: string,
    increaseValue: boolean,
  ) => {
    setRecalculateShipping(true);
    const updatedProducts = selectedProducts.map((item) => {
      if (
        item.productId === productId &&
        item.productColorID === productColorID &&
        item.variantId === variantId
      ) {
        return {
          ...item,
          quantity: increaseValue
            ? Math.min(item.quantity + 1, 99)
            : Math.max(item.quantity - 1, 0),
        };
      }
      return item;
    });
    dispatch(setSelectedProduct(updatedProducts));
  };

  const calculateShipping = async () => {
    const products = generateSampleOrderShippingCostPayload(selectedProducts);
    try {
      const res = await getShippingCost({ products });
      setRecalculateShipping(false);
      if ('data' in res) {
        dispatch(
          setTotalCost({ ...totalCost, shippingCost: res.data.shippingCost }),
        );
      }
    } catch (error) {}
  };

  return (
    <StyledWrapper className="show-no-scrollbar b-r-6px">
      {isLoading && <SmallLoader />}
      <FlexBox alignItems="center">
        <Typography
          text="Cart"
          fontSize="18px"
          secondaryColorValue={900}
          className="cart-heading"
        />
        {selectedProducts.find((product) => product.isPersonalizable) && (
          <CustomToolTip
            title="Your cart contains personalized products"
            placement="top"
          >
            <Typography
              className="personalization-indicator"
              text="P"
              fontSize="0.8rem"
              fontWeight="900"
            />
          </CustomToolTip>
        )}
      </FlexBox>
      <div className="show-no-scrollbar">
        {selectedProducts.map((product, index) => {
          const isVaraintOutOfStock = outOfStockItems.includes(
            product.catalogSKUId as any,
          );
          return (
            <ProductWrapper
              key={index}
              isOutOfStock={isVaraintOutOfStock}
              className="product-container"
            >
              <div className="product-image">
                <img src={product.image} alt="product_image" />
                {isVaraintOutOfStock && (
                  <div className="text-overlay">Out Of Stock</div>
                )}
              </div>
              <FlexBox gap="8px" direction="column" className="product-details">
                <div className="title-container">
                  <span>{product.title}</span>
                  {(currentStep !== 3 || isVaraintOutOfStock) && (
                    <img
                      src={DeleteIcon}
                      className="delete-icon grey-border b-r-6px"
                      alt="Delete"
                      onClick={() =>
                        handleDelete(product.printSKUId, product.productId)
                      }
                    />
                  )}
                </div>

                <FlexBox
                  alignItems="center"
                  className="details-container"
                  justifyContent="space-between"
                >
                  <FlexBox
                    alignItems="center"
                    justifyContent="space-between"
                    gap="10px"
                  >
                    <ColoredCircle
                      colorCode={`${product?.color}`}
                      width={18}
                      height={18}
                    />
                    <span className="small-dot"></span>
                    <div className="product-size">{product.size ?? '--'}</div>
                  </FlexBox>
                  <FlexBox
                    className="border-box"
                    style={{ width: 'fit-content' }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <span
                      style={{
                        padding: '5px',
                        cursor: 'pointer',
                        color: product.quantity === 1 ? colors.grey[300] : '',
                      }}
                      onClick={
                        product.quantity > 1
                          ? () =>
                              updateProductQuantity(
                                product.productId,
                                product.productColorID,
                                product.variantId,
                                false,
                              )
                          : undefined
                      }
                    >
                      -
                    </span>
                    <Input
                      type="text"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      onKeyDown={(event) => {
                        const key: any = event.key;

                        const isNumeric = !isNaN(key) || key === 'Backspace';

                        if (!isNumeric) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) =>
                        handleInputChange(
                          product.productId,
                          product.productColorID,
                          product.variantId,
                          parseInt(event.target.value),
                        )
                      }
                      onBlur={(event) => {
                        const value = parseInt(event.target.value);
                        if (isNaN(value) || value < 1) {
                          handleInputChange(
                            product.productId,
                            product.productColorID,
                            product.variantId,
                            1,
                          );
                        }
                      }}
                      value={product.quantity.toString()}
                      style={{
                        width: '24px',
                        textAlign: 'center',
                        boxShadow: 'none',
                      }}
                    />
                    <span
                      style={{
                        padding: '5px',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        updateProductQuantity(
                          product.productId,
                          product.productColorID,
                          product.variantId,
                          true,
                        )
                      }
                    >
                      +
                    </span>
                  </FlexBox>
                  <FlexBox>
                    <Typography
                      text={`USD ${calculateProductPrice(
                        product.variantId,
                        product.quantity,
                      )}`}
                      fontSize={'14px'}
                    />
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            </ProductWrapper>
          );
        })}
      </div>
      <FlexBox direction="column">
        {selectedProducts.length > 0 && (
          <>
            <FlexBox
              alignItems="center"
              justifyContent="space-between"
              className="totalCost-wrapper"
            >
              <Typography text="Production" fontSize={'14px'} />
              <Typography
                text={`USD ${totalCost.totalProductionCost.toFixed(2)}`}
                fontSize={'14px'}
              />
            </FlexBox>

            {
              <FlexBox
                alignItems="center"
                justifyContent="space-between"
                className="shipping-title"
              >
                <Typography text="Shipping cost" fontSize={'14px'} />

                <span color={colors.grey[900]} className="f-s-14">
                  {totalCost.shippingCost === 0
                    ? currentStep === 2
                      ? '---'
                      : currentStep !== 3 && 'Calculated at next step'
                    : `USD ${totalCost.shippingCost.toFixed(2)}`}
                </span>

                {recalculateShipping && currentStep === 3 && (
                  <Button
                    onClick={() => calculateShipping()}
                    label="Recalculate"
                    appearance="secondary"
                  />
                )}
              </FlexBox>
            }
          </>
        )}
      </FlexBox>
      <FlexBox
        alignItems="center"
        className=" heading b-t"
        justifyContent="space-between"
      >
        <Typography text="Total" fontSize={'14px'} fontWeight="700" />

        <span color={colors.grey[900]} className=" f-s-14 f-w-700">{`USD ${(
          totalCost.shippingCost + totalCost.totalProductionCost
        ).toFixed(2)}`}</span>
      </FlexBox>
    </StyledWrapper>
  );
};

export default Cart;

const StyledWrapper = styled.div`
  border: 1px solid rgb(235, 235, 235);
  height: fit-content;
  width: 47%;
  max-height: 544px;
  display: flex;
  flex-direction: column;
  .cart-heading {
    padding: 15px 16px;
  }
  .heading {
    padding: 10px 15px !important;
  }
  .shipping-title {
    padding: 0 15px 10px !important;
  }
  .totalCost-wrapper {
    padding: 10px 15px;
  }
`;

const ProductWrapper = styled.div<{ isOutOfStock: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  padding: 8px 16px;
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.grey.border};
  margin-top: 5px;
  &:first-child {
    border-top: 1px solid ${colors.grey.border};
  }

  ${({ isOutOfStock }) =>
    isOutOfStock &&
    css`
      .product-image img,
      .title-container span,
      .details-container {
        opacity: 0.2;
      }
      .product-image {
        position: relative;
      }
      .text-overlay {
        position: absolute;
        z-index: 1;
        color: ${colors.red[600]};
        top: 50%;
        left: 50%;
        width: 100%;
        text-align: center;
        transform: translate(-50%, -50%);
      }
    `}

  .product-image img {
    width: 64px;
    height: 64px;
    border-radius: 6px;
    border: 1px solid ${colors.grey[150]};
    object-fit: contain;
  }

  .product-container {
    width: 100%;
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
  }
  .product-details {
    width: 80%;
  }
  .delete-icon {
    height: 20px;
    width: 20px;
  }

  .border-box {
    border: 1px solid ${colors.grey.border};
    border-radius: 6px;
  }
`;
