import { fabric } from 'fabric';
import { PersonalizationType } from '../interface';
import { lastAddedElementIDByType } from '../components/editor/utils';
import { store } from '../store';
import { setBase64Cache } from '../store/slices/editor';
import { ProductType } from '../interface/product-interface';

fabric.CustomImage = fabric.util.createClass(fabric.Image, {
  type: 'custom-image',

  initialize: function (element, options) {
    this.callSuper('initialize', element, options);
    options &&
      this.set({
        id: options.id,
        name: options.name,
        personalizationData: options.personalizationData,
        originalSrc: options.originalSrc,
        originalWidth: options.originalWidth,
        originalHeight: options.originalHeight,
        originalImageBlobSrc: options.originalImageBlobSrc,
      });
  },

  toObject: function () {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      name: this.name,
      id: this.id,
      personalizationData: this.personalizationData,
      originalSrc: this.originalSrc,
      originalWidth: this.originalWidth,
      originalHeight: this.originalHeight,
      originalImageBlobSrc: this.originalImageBlobSrc,
    });
  },
});

fabric.CustomImage.fromObject = function (object, callback) {
  fabric.util.loadImage(object.src, function (img) {
    callback && callback(new fabric.CustomImage(img, object));
  });
};

fabric.CustomImage.async = true;

export function downscaleImage(image, maxWidth, maxHeight) {
  const offscreenCanvas = document.createElement('canvas');
  const ctx = offscreenCanvas.getContext('2d');

  let width = image.width;
  let height = image.height;

  const aspectRatio = width / height;

  if (width > maxWidth) {
    width = maxWidth;
    height = width / aspectRatio;
  }

  if (height > maxHeight) {
    height = maxHeight;
    width = height * aspectRatio;
  }

  offscreenCanvas.width = width;
  offscreenCanvas.height = height;

  ctx.imageSmoothingEnabled = true;
  ctx.imageSmoothingQuality = 'high';

  ctx.drawImage(image, 0, 0, width, height);

  return new Promise((resolve) => {
    offscreenCanvas.toBlob(
      (blob) => {
        const blobURL = URL.createObjectURL(blob);
        resolve(blobURL);
      },
      'image/png',
      1,
    );
  });
}

export function createImage(
  src,
  lastAddedId,
  drawingBoard,
  isPersonalized,
  name,
  currentProductType,
  originalSrc,
) {
  let imgObj = new Image();
  imgObj.src = src;

  imgObj.onload = async function () {
    const originalWidth = imgObj.width;
    const originalHeight = imgObj.height;

    const aspectRatio = originalWidth / originalHeight;

    let maxWidth, maxHeight;
    if (
      currentProductType !== ProductType.ApparelLight ||
      currentProductType !== ProductType.ApparelHeavy ||
      currentProductType !== ProductType.ToteBag
    ) {
      if (currentProductType !== ProductType.Ornament) {
        maxWidth = drawingBoard?.width;
        maxHeight = drawingBoard?.height;
      } else {
        maxWidth = 200;
        maxHeight = 300;
      }
    }
    /** */
    let scaledWidth, scaledHeight;
    if (aspectRatio > 1) {
      scaledWidth = Math.min(maxWidth, originalWidth);
      scaledHeight = scaledWidth / aspectRatio;
      if (scaledHeight > maxHeight) {
        scaledHeight = maxHeight;
        scaledWidth = scaledHeight * aspectRatio;
      }
    } else {
      scaledHeight = Math.min(maxHeight, originalHeight);
      scaledWidth = scaledHeight * aspectRatio;
      if (scaledWidth > maxWidth) {
        scaledWidth = maxWidth;
        scaledHeight = scaledWidth / aspectRatio;
      }
    }
    /** */
    let scaleToWidth = scaledWidth;
    let scaleToHight = scaledHeight;

    if (
      currentProductType === ProductType.ApparelLight ||
      currentProductType === ProductType.ApparelHeavy ||
      currentProductType === ProductType.ToteBag
    ) {
      scaleToHight = drawingBoard?.clipPath.height;
      scaleToWidth = drawingBoard?.clipPath.width;
    }

    const downscaledSrc = await downscaleImage(
      imgObj,
      drawingBoard?.width,
      drawingBoard?.height,
    );

    let img = new Image();
    img.src = downscaledSrc;

    img.onload = function () {
      let fabricImg = new fabric.CustomImage(img, {
        id: 'image-' + lastAddedId,
        objectCaching: false,
        name: name,
        originX: 'center',
        originY: 'center',
        originalSrc: originalSrc ?? '',
        originalImageBlobSrc: src,
        originalWidth: originalWidth,
        originalHeight: originalHeight,
      });

      if (isPersonalized) {
        fabricImg.set({
          personalizationData: {
            personalizationType: PersonalizationType.ALLOW_BUYERS_TO_UPLOAD,
            isLocked: false,
            imageOptions: [],
          },
        });
      }

      fabricImg.scaleToHeight(scaleToHight);
      fabricImg.scaleToWidth(scaleToWidth);

      if (
        currentProductType !== ProductType.ApparelLight &&
        currentProductType !== ProductType.ApparelHeavy &&
        currentProductType !== ProductType.ToteBag
      ) {
        fabricImg.set({
          left: drawingBoard?.width / 2,
          top: drawingBoard?.height / 2,
        });
      }

      drawingBoard?.add(fabricImg);
      drawingBoard?.setActiveObject(fabricImg);
      drawingBoard?.renderAll();
    };
  };
}

export function addCustomImage(
  files,
  drawingBoard,
  allFiles,
  isPersonalized = false,
  fromUrl = false,
  fileName = '',
  currentProductType,
  originalSrc,
) {
  let lastAddedId = lastAddedElementIDByType('custom-image', allFiles);
  lastAddedId++;
  let reader = new FileReader();
  const name = fromUrl ? fileName : isPersonalized ? 'default' : files[0].name;

  reader.onload = function (event) {
    const blobUrl = URL.createObjectURL(files[0]);
    store.dispatch(setBase64Cache({ blobUrl, base64: event.target.result }));
    createImage(
      blobUrl,
      lastAddedId,
      drawingBoard,
      isPersonalized,
      name,
      currentProductType,
      originalSrc,
    );
  };

  if (!isPersonalized && files[0]) {
    reader.readAsDataURL(files[0]);
  } else {
    createImage(
      files,
      lastAddedId,
      drawingBoard,
      fromUrl ? false : isPersonalized,
      name,
      currentProductType,
      originalSrc,
    );
  }
}

export function getImage(src) {
  let imgObj = new Image();
  imgObj.src = src;

  // When the picture loads, create the image in Fabric.js
  imgObj.onload = function () {
    let img = new fabric.CustomImage(imgObj, {
      id: 'image-6',
      objectCaching: true,
      name: 'skull',
    });
    img.scaleToHeight(150);
    img.scaleToWidth(100);
    return img;
  };
}
