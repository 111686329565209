import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQuery';
import {
  PaginatedResponse,
  PublishProductQueryParams,
  PublishRespones,
  ResultsArray,
  UpdateProductQueryParams,
} from '../../interface';
import { PrintProvider } from '../../interface/catalog-interfaces';
import { appendQueryParams } from '../../helper/appenedQueryParams';
import {
  MyProductsQueryParams,
  MyProductsResponse,
  SingleProductResponse,
  SingleProductQueryParams,
  UpdateProductDetailsQueryParams,
} from '../../interface/product-interface';
import {
  SampleOrderQueryParams,
  SampleOrderProduct,
} from '../../interface/sample-order-interface-v2';

export const productsAPI = createApi({
  reducerPath: 'products',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['Products'],
  endpoints: (builder) => ({
    getMyProducts: builder.query<MyProductsResponse, MyProductsQueryParams>({
      query: (params) => {
        var url = `/products`;
        return appendQueryParams(url, params);
      },
      keepUnusedDataFor: 0,
      providesTags: ['Products'],
    }),
    getRawProductDetails: builder.query<
      SingleProductResponse,
      SingleProductQueryParams
    >({
      query: ({ productId, fromMockups, triggerUpdate }) =>
        `/products/${productId}?${
          fromMockups ? 'fromMockups=true' : 'isEditing=true'
        }${triggerUpdate ? '&triggerUpdate=true' : ''}`,
      keepUnusedDataFor: 0,
    }),
    getProductDetailsForDetailsPage: builder.query<
      SingleProductResponse,
      SingleProductQueryParams
    >({
      query: ({ productId }) => `/products/${productId}`,
      keepUnusedDataFor: 0,
    }),
    updateSingleProduct: builder.mutation<
      any, // will update `SingleProductResponse` interface when we start working on it
      UpdateProductDetailsQueryParams
    >({
      query: (params) => ({
        url: `/products/${params.productId}`,
        method: 'PATCH',
        body: params.body,
      }),

      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: updatedProduct } = await queryFulfilled;
          for (const {
            endpointName,
            originalArgs,
          } of productsAPI.util.selectInvalidatedBy(getState(), [
            { type: 'Products' },
          ])) {
            if (endpointName !== 'getMyProducts') continue;
            dispatch(
              productsAPI.util.updateQueryData(
                endpointName,
                originalArgs,
                (draft) => {
                  const data = draft;
                  const index = data.results.findIndex(
                    (product) => product._id === params.productId,
                  );
                  if (index >= 0) {
                    data.results.splice(index, 1, {
                      ...updatedProduct.data,
                      _id: updatedProduct.data.id,
                    });
                  }
                  return data;
                },
              ),
            );
          }
        } catch {}
      },
    }),
    createProduct: builder.mutation<any, { body: {} }>({
      query: (params) => ({
        url: `/products`,
        method: 'POST',
        body: params.body,
      }),
    }),
    getPrintProviderInfoById: builder.query<
      PrintProvider,
      { printProviderId: string }
    >({
      query: (printProviderId) => ({
        url: `/products/print-provider/?printProviderId=${printProviderId}`,
        method: 'GET',
      }),
    }),
    deleteProductById: builder.mutation<ResultsArray, string[]>({
      query: (productIds) => ({
        url: `/products/delete?&ids[]=${productIds.join('&ids[]=')}`,
        method: 'DELETE',
      }),
      async onQueryStarted(productIds, { dispatch, getState, queryFulfilled }) {
        try {
          await queryFulfilled;
          for (const {
            endpointName,
            originalArgs,
          } of productsAPI.util.selectInvalidatedBy(getState(), [
            { type: 'Products' },
          ])) {
            // we only want to update `getMyProducts` here
            if (endpointName !== 'getMyProducts') continue;
            dispatch(
              productsAPI.util.updateQueryData(
                endpointName,
                originalArgs,
                (draft) => {
                  const data = draft;
                  data.results = draft.results.filter(
                    (product) => !productIds.includes(product._id),
                  );
                  return data;
                },
              ),
            );
          }
        } catch {}
      },
    }),
    publishProduct: builder.mutation<
      PublishRespones,
      PublishProductQueryParams
    >({
      query: (params) => ({
        url: `/products/publish?ids[]=${params.productIds.join('&ids[]=')}`,
        method: 'POST',
        body: {
          retryCount: params.retryCount,
        },
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Products']),
    }),
    updateProductDesign: builder.mutation<
      SingleProductResponse,
      UpdateProductQueryParams
    >({
      query: (param) => ({
        url: `/products/update/${param.productId}${
          param.designUpdated ? '?designUpdated=true' : ''
        }`,
        method: 'POST',
        body: param.body,
      }),
    }),

    updateProductDesignFiles: builder.mutation<
      SingleProductResponse,
      UpdateProductQueryParams
    >({
      query: (param) => ({
        url: `/products/update/${
          param.productId
        }${`?isNewProduct=${param.isNewProduct}`}`,
        method: 'PATCH',
        body: param.body,
      }),
    }),
    uploadMockups: builder.mutation<
      SingleProductResponse,
      { formData: FormData; productId: string | undefined }
    >({
      query: (params) => ({
        url: `/products/mockups/${params.productId}`,
        method: 'POST',
        body: params.formData,
      }),
    }),
    duplicateProduct: builder.mutation<void, string[]>({
      query: (productIds) => ({
        url: `/products/duplicate?&ids[]=${productIds.join('&ids[]=')}`,
        method: 'POST',
      }),
    }),
    getSampleOrderProducts: builder.query<
      PaginatedResponse<SampleOrderProduct>,
      SampleOrderQueryParams
    >({
      query: (params) => {
        return appendQueryParams('/products/sample-orders', params);
      },
      keepUnusedDataFor: 0,
    }),
    dicardUnpublishedChanges: builder.mutation<void, string>({
      query: (productId) => ({
        url: `/products/dicard-unpublished-changes/${productId}`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Products']),
    }),
  }),
});

export const {
  useGetMyProductsQuery,
  useGetRawProductDetailsQuery,
  useUpdateSingleProductMutation,
  useGetPrintProviderInfoByIdQuery,
  useCreateProductMutation,
  useDeleteProductByIdMutation,
  usePublishProductMutation,
  useUpdateProductDesignMutation,
  useUpdateProductDesignFilesMutation,
  useUploadMockupsMutation,
  useDuplicateProductMutation,
  useGetProductDetailsForDetailsPageQuery,
  useGetSampleOrderProductsQuery,
  useDicardUnpublishedChangesMutation,
} = productsAPI;
