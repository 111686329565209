import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import Loader from '../../../common/loader';
import ProductAngles from '../product-angles';
import { FlexBox } from '../../../common/wrappers';
import { DropzoneRef } from 'react-dropzone';
import { DropzonePlaceholder } from '../../../../assets/icons';
import { Canvas } from 'fabric/fabric-impl';

import {
  PrintArea,
  VariantsV2,
} from '../../../../interface/catalog-interface-v2';
import { DataToGenerateAngleImage } from '../../../../interface';
import { generatePreviewImages } from './utils';
import { colors } from '../../../../assets/colors';
import { ProductType } from '../../../../interface/product-interface';

interface Props {
  addImageLoader: boolean;
  isAnglesLoading: boolean;
  isTablet: boolean;
  isKeyLongPressed: boolean;
  selectedAngle: {
    angle: string;
    image: string;
    originalImage: any;
  };
  selectedVariant: VariantsV2 | undefined;
  handleAngleChange: (angle: string, source: string) => void;
  addToGallery: (files: any) => void;
  files: any[];
  ToolBar: ReactNode;
  UndoRedoComponent: ReactNode;
  drawingBoard: Canvas | undefined;
  dataToGenerateAngleImage: DataToGenerateAngleImage[];
  printArea: PrintArea | undefined;
  isProductInfoFetching: boolean;
  currentProductType: string;
  preloadedImages: {
    [key: string]: string;
  };
  setAddImageLoader: React.Dispatch<React.SetStateAction<boolean>>;
  apparelCanvasRef: React.RefObject<HTMLDivElement>;
  dropzoneRef: React.MutableRefObject<DropzoneRef | null>;
  printAreaDimension: any;
}

const ApparelEditor: React.FC<Props> = ({
  addImageLoader,
  isAnglesLoading,
  isTablet,
  isKeyLongPressed,
  selectedAngle,
  selectedVariant,
  handleAngleChange,
  addToGallery,
  files,
  ToolBar,
  UndoRedoComponent,
  drawingBoard,
  dataToGenerateAngleImage,
  printArea,
  isProductInfoFetching,
  currentProductType,
  preloadedImages,
  setAddImageLoader,
  apparelCanvasRef,
  dropzoneRef,
  printAreaDimension,
}) => {
  const [imageDimension, setImageDimension] = useState({
    orignal: { height: 0, width: 0 },
    mockup: { height: 0, width: 0 },
  });

  const [angleImages, setAngleImages] = useState<{ [key: string]: string }>();

  useEffect(() => {
    async function startImageGeneration() {
      let copyAngles: any = { ...angleImages };
      for (const data of dataToGenerateAngleImage) {
        const { imageData, catalogData, variant, pixiApp, angle } = data;
        const img = await generatePreviewImages(
          imageData,
          catalogData,
          variant,
          pixiApp,
          angle,
          preloadedImages,
        );
        copyAngles = { ...copyAngles, [angle]: img };
      }
      setAngleImages(copyAngles);
    }
    startImageGeneration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataToGenerateAngleImage]);

  useEffect(() => {
    const container = document.getElementsByClassName(
      'canvas-container',
    ) as HTMLCollectionOf<HTMLElement>;
    if (container[0]) {
      if (files.length === 0) {
        container[0].style.display = 'none';
      } else {
        container[0].style.display = 'block';
        for (const ele of Array.from(
          document.getElementsByTagName(
            'canvas',
          ) as HTMLCollectionOf<HTMLElement>,
        )) {
          ele.style.display = 'block';
        }
      }
    }
  }, [files, drawingBoard]);

  useEffect(() => {
    const getMeta = (url: string, cb: any) => {
      const img = new Image();
      img.onload = () => cb(null, img);
      img.onerror = (err) => cb(err);
      img.src = url;
    };

    getMeta(selectedAngle.originalImage, (err: any, img: any) => {
      if (img) {
        const containerWidth = drawingBoard?.width || 0;
        const containerHeight = drawingBoard?.height || 0;
        const originalWidth = img.naturalWidth;
        const originalHeight = img.naturalHeight;
        const scaleFactorForOriginal = Math.min(
          containerWidth / originalWidth,
          containerHeight / originalHeight,
        );
        const newOWidth = originalWidth * scaleFactorForOriginal;
        const newOHeight = originalHeight * scaleFactorForOriginal;
        const scaleFactorForMockup = Math.min(
          164 / originalWidth,
          164 / originalHeight,
        );
        setImageDimension({
          orignal: { width: newOWidth, height: newOHeight },
          mockup: {
            height: originalHeight * scaleFactorForMockup,
            width: originalWidth * scaleFactorForMockup,
          },
        });
      }
    });
  }, [drawingBoard?.height, drawingBoard?.width, selectedAngle.originalImage]);

  return (
    <>
      {(addImageLoader || isAnglesLoading) && <Loader disableBackDrop={true} />}
      <Wrapper
        style={{ display: isProductInfoFetching ? 'none' : 'flex' }}
        isTablet={isTablet}
        isToteBagEditor={currentProductType === ProductType.ToteBag}
      >
        {/*Angle section start */}
        {selectedVariant && (
          <ProductAngles
            isAnglesLoading={isAnglesLoading || isKeyLongPressed}
            angle={selectedAngle.angle}
            selectedImage={selectedAngle.image}
            angleImages={angleImages}
            images={selectedVariant?.images}
            onClick={(angle, image) =>
              handleAngleChange(angle, image.target.src)
            }
            printArea={printArea}
          />
        )}
        {/*Angle section ends */}

        {/*Drawing section start */}
        <DrawingWrapper>
          <FlexBox
            style={{
              width: '100%',
              height: 'auto',
            }}
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            {/* <TshirtImage /> */}
            <img
              alt="product"
              src={
                preloadedImages[selectedAngle.originalImage] ||
                selectedAngle.originalImage
              }
              id="selectedProductImage"
              width={imageDimension.orignal.width}
              height={imageDimension.orignal.height}
            />

            <FlexBox
              justifyContent="center"
              direction="column"
              alignItems="center"
              className="canvas"
              id="canvas-parent"
              style={{
                backgroundColor: 'transparent',
                border: 'none',
              }}
              ref={apparelCanvasRef}
              onClick={() => {
                if (dropzoneRef.current && files.length === 0) {
                  dropzoneRef.current.open();
                }
              }}
            >
              <section
                style={{
                  display: files.length === 0 ? 'block' : 'none',
                }}
              >
                <FlexBox
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  className="dropzone-wrapper"
                >
                  <img
                    src={DropzonePlaceholder}
                    alt="Dropzone"
                    height={'100'}
                  />
                  <p className="fs-16" style={{ margin: '2px' }}>
                    Drag and drop your design here or{' '}
                    <span
                      style={{
                        color: colors.blue[900],
                      }}
                    >
                      Click to Upload
                    </span>
                  </p>
                  <FlexBox direction="column" className="text-align-center">
                    <span className="fs-14">
                      Print area size {printAreaDimension?.width} ×{' '}
                      {printAreaDimension?.height} px (300 DPI)
                    </span>

                    <span className="fs-14">Maximum 25 MB (JPG, PNG, SVG)</span>
                    <span className="fs-14">
                      Maximum resolution 25000 x 25000 px
                    </span>
                  </FlexBox>
                </FlexBox>
              </section>
              <canvas
                height={550}
                width={500}
                style={{
                  display: files.length === 0 ? 'none' : 'block',
                }}
                id="drawingArea"
              />
              {ToolBar}
            </FlexBox>
          </FlexBox>
        </DrawingWrapper>
        {/*Drawing section ends */}
        <div
          className="absolute"
          style={{
            bottom: '16%',
            left: '10px',
            userSelect: 'none',
          }}
        >
          {UndoRedoComponent}
        </div>
      </Wrapper>
    </>
  );
};

export default ApparelEditor;

const Wrapper = styled.div<{ isTablet: boolean; isToteBagEditor: boolean }>`
  padding: 16px 32px;
  display: flex;
  overflow: hidden;
  height: 100vh;
  justify-content: initial;

  gap: ${(props) => props.isTablet && '5rem'};
  flex-direction: ${(props) => props.isTablet && 'column'};
  > div:first-child {
    width: ${(props) => {
      return !props.isTablet && '30%';
    }};
  }
  > div:nth-child(2) {
    width: ${(props) => !props.isTablet && '70%'};
  }
  .dropzone-wrapper {
    background: rgba(255, 255, 255, 0.5);
    height: 250px;
    width: 230px;
    padding: 6px;
    position: ${(props) => props.isToteBagEditor && 'relative'};
    top: ${(props) => props.isToteBagEditor && '100px'};
  }
`;

const DrawingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-top: 0;
`;
