// PaymentStep .tsx

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FlexBox } from '../common/wrappers';
import { ReactComponent as PlusCircleIcon } from '../../assets/images/plus-circle.svg';
import { useAppSelector } from '../../store/hooks';
import {
  updateBillingSameAsShippingAddress,
  setAddress,
  getSampleOrderData,
  setSampleOrderPaymentId,
  setAttachPaymentMethod,
} from '../../store/slices/sampleOrderSlice';
import { CardTile } from '../payment-modal';
import { useGetCardDetailsQuery } from '../../store/services/user';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Checkbox from '../checkbox';
import { AddressForm } from './ShippingDetailsStep';
import Typography from '../typography';
import CheckoutForm from '../payment-modal/checkout-form';
import { colors } from '../../assets/colors';
import { AuthInitialState, getUserData } from '../../store/slices/authSlice';
import SmallLoader from '../common/small-loader';
import { Address } from '../../interface/create-order-interface';
import { ReactComponent as ErrorIcon } from '../../assets/images/danger.svg';

interface Props {
  setIsFulfillmentButtonDisabled(status: boolean): void;
  generatePayentMethodId: boolean;
  errorMessage: string;
  setPaymentError: (error: string) => void;
}

const PaymentStep: React.FC<Props> = ({
  setIsFulfillmentButtonDisabled,
  generatePayentMethodId,
  errorMessage,
  setPaymentError,
}) => {
  const {
    billingSameAsShippingAddress,
    shippingAddress,
    billingAddress,
    sampleOrderPaymentId,
    selectedProducts,
    outOfStockItems,
  } = useAppSelector(getSampleOrderData);
  const {
    userInfo: { paymentMethodId },
  }: AuthInitialState = useAppSelector(getUserData);
  const { data: cardsList, isFetching } = useGetCardDetailsQuery();
  const dispatch = useDispatch();
  const [showAddNewCard, setShowAddNewCard] = useState(false);
  const handleBillingSameAsShippingChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(
      updateBillingSameAsShippingAddress({
        billingSameAsShippingAddress: e.target.checked,
      }),
    );
  };
  useEffect(() => {
    return () => {
      setPaymentError('');
    };
  }, [setPaymentError]);
  let CardLength: boolean = (cardsList ?? []).length > 0;
  useEffect(() => {
    if (CardLength) {
      dispatch(setSampleOrderPaymentId(paymentMethodId));
    }
  }, [CardLength, dispatch, paymentMethodId]);
  const handleBillingAddressChange = (field: keyof Address, value: string) => {
    dispatch(
      setAddress({
        address: { ...billingAddress, [field]: value },
        isBilling: true,
      }),
    );
  };

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ??
      'pk_test_51Ij5LLAprxaDl4vmQnejLIWCB5k03PlXbsAUdvzmO58OzBRXHBJXK5JPNL2AKgMZxUTNV6e6Uy1fjGorMz21bqxe001jgLdSd6',
  );

  useEffect(() => {
    dispatch(
      updateBillingSameAsShippingAddress({
        billingSameAsShippingAddress: true,
      }),
    );
  }, [dispatch]);

  const isItemOutOfStock = selectedProducts.some(
    (product: { catalogSKUId: any }) =>
      outOfStockItems.includes(product.catalogSKUId),
  );
  const showError = errorMessage.includes('out of stock')
    ? isItemOutOfStock
    : !!errorMessage;
  return (
    <>
      {showError && (
        <FlexBox gap="0.5rem" alignItems="center" className="error-message">
          <ErrorIcon height="1.3rem" width="1.3rem" />
          <span>{errorMessage}</span>
        </FlexBox>
      )}

      {isFetching ? (
        <SmallLoader />
      ) : (
        <>
          {CardLength && (
            <FlexBox
              direction="column"
              gap="0.5rem"
              className="show-no-scrollbar"
              style={{ marginTop: '10px' }}
            >
              {cardsList && (
                <>
                  <FlexBox
                    direction="column"
                    gap="0.5rem"
                    className="show-no-scrollbar"
                    style={{ marginTop: '10px' }}
                  >
                    {cardsList.map((card) => (
                      <CardTile
                        cardInfo={card}
                        isActive={
                          sampleOrderPaymentId === card.id && !showAddNewCard
                        }
                        key={card.id}
                        onClick={() => {
                          dispatch(setSampleOrderPaymentId(card.id));
                          setShowAddNewCard(false);
                          dispatch(setAttachPaymentMethod(false));
                        }}
                      />
                    ))}
                  </FlexBox>
                  <FlexBox
                    alignItems="center"
                    gap="0.5rem"
                    direction="column"
                    className="add-payment-btn b-r-6px grey-border"
                    style={{
                      padding: '8px 16px',
                      backgroundColor: showAddNewCard
                        ? colors.blue[100]
                        : 'white',
                    }}
                  >
                    <FlexBox
                      alignItems="center"
                      gap="0.5rem"
                      className="pointer-cursor"
                      style={{ width: '100%' }}
                      onClick={() => {
                        setShowAddNewCard((prevState) => !prevState);
                        dispatch(setSampleOrderPaymentId(null));
                      }}
                    >
                      <PlusCircleIcon width="20px" height="25px" />
                      <Typography text="Add payment method" fontSize="14px" />
                    </FlexBox>
                    {showAddNewCard && (
                      <>
                        <div className="card-form-wrapper b-b">
                          {stripePromise && (
                            <Elements stripe={stripePromise}>
                              <CheckoutForm
                                onCloseModal={() => setShowAddNewCard(true)}
                                forSampleOrder={true}
                                setIsFulfillmentButtonDisabled={
                                  setIsFulfillmentButtonDisabled
                                }
                                generatePayentMethodId={generatePayentMethodId}
                                setPaymentError={setPaymentError}
                              />
                            </Elements>
                          )}
                        </div>
                        <FlexBox
                          className="b-r-6px w-100 adress-wrapper m-0"
                          direction="column"
                        >
                          <FlexBox justifyContent="space-between">
                            <Typography
                              text="Billing Address"
                              fontSize="18px"
                            />
                            <Checkbox
                              label={'Same as shipping address'}
                              checked={billingSameAsShippingAddress}
                              onChange={handleBillingSameAsShippingChange}
                            />
                          </FlexBox>
                          {!billingSameAsShippingAddress ? (
                            <AddressForm
                              address={billingAddress}
                              handleAddressChange={handleBillingAddressChange}
                              borderRequired={false}
                            />
                          ) : (
                            <>
                              <Typography
                                className="p-y-10"
                                fontSize="14px"
                                text={`${shippingAddress.address}`}
                              />
                            </>
                          )}
                        </FlexBox>
                      </>
                    )}
                  </FlexBox>
                </>
              )}
            </FlexBox>
          )}
          {!CardLength && (
            <>
              <FlexBox
                className="grey-border b-r-6px card-wrapper"
                direction="column"
                gap="0.5rem"
              >
                <Typography
                  text="Add credit/debit card to your account"
                  fontSize="0.8rem"
                  fontWeight="800"
                  secondaryColorValue={900}
                  classname="sub-heading"
                />
                {stripePromise && (
                  <Elements stripe={stripePromise}>
                    <CheckoutForm
                      onCloseModal={() => setShowAddNewCard(true)}
                      forSampleOrder={true}
                      setIsFulfillmentButtonDisabled={
                        setIsFulfillmentButtonDisabled
                      }
                      generatePayentMethodId={generatePayentMethodId}
                      setPaymentError={setPaymentError}
                    />
                  </Elements>
                )}
              </FlexBox>
              <FlexBox
                className="grey-border b-r-6px adress-wrapper"
                direction="column"
              >
                <FlexBox justifyContent="space-between">
                  <Typography text="Billing Address" fontSize="18px" />
                  <Checkbox
                    label={'Same as shipping address'}
                    checked={billingSameAsShippingAddress}
                    onChange={handleBillingSameAsShippingChange}
                  />
                </FlexBox>
                {!billingSameAsShippingAddress ? (
                  <AddressForm
                    address={billingAddress}
                    handleAddressChange={handleBillingAddressChange}
                    borderRequired={true}
                  />
                ) : (
                  <>
                    <Typography
                      className="p-y-10"
                      fontSize="14px"
                      text={`${shippingAddress.address}`}
                    />
                  </>
                )}
              </FlexBox>
            </>
          )}
        </>
      )}
    </>
  );
};

export default PaymentStep;
