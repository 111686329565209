import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from '../../../../assets/colors';
import { Cross } from '../../../../assets/icons';
import { FlexBox } from '../../../common/wrappers';
import { useLocation, useNavigate } from 'react-router-dom';
import { routeNames } from '../../../../constants/routesPath';
import { useAppSelector } from '../../../../store/hooks';
import {
  getUtilityData,
  setDataChanged,
} from '../../../../store/slices/utilitySlice';
import StepSystem from './step-system';
import TopBarCancelModal from './TopBarCancelModal';
import ConfirmationModal from './ConfirmationModal';
import { getDetails } from '../../../../store/slices/detailsSlice';
import { compareObj as isDataEqual } from '../../utils';
import {
  PublishingSettings,
  printProviderInfo,
} from '../../../../interface/product-interface';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { getMockups } from '../../../../store/slices/mockupSlice';
import { SelectedImage } from '../../../../interface/mockups-interface';
import { getOrnamentsModel } from '../ornaments/utils';
import CustomToolTip from '../../../custom-tooltip';

interface Props {
  title?: string | undefined;
  onSave: () => void;
  originalData?: any;
  updatedData?: any;
  currentPage?: 'editor' | 'details' | 'mockups';
  printProviderInfo: printProviderInfo;
  isTablet: boolean;
}

const TopBar: React.FC<Props> = ({
  title,
  onSave,
  originalData,
  updatedData,
  currentPage,
  printProviderInfo,
  isTablet,
}) => {
  const details = useAppSelector(getDetails);
  const location = useLocation();
  const navigate = useNavigate();
  const [cancelModal, setCancelModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const {
    printProviderId,
    catalogueId,
    productId,
    modelNumber,
    isDetailsVisited,
    isMockupsVisited,
    isDataSaved,
  } = useAppSelector(getUtilityData);
  const dispatch = useDispatch();
  const extractDescriptionText = (descriptionObject: any): string =>
    descriptionObject?.blocks
      ?.map((block: { text: string }) => block.text)
      .join('\n') || '';
  const { selectedImages } = useAppSelector(getMockups);

  const compareSyncingOptions = (
    originalSyncOptions: any,
    changedSyncOptions: any,
  ): boolean =>
    [
      'syncProductTitle',
      'syncDescription',
      'syncVariants',
      'syncMockups',
      'syncShippingProfiles',
    ].every((key) => originalSyncOptions[key] === changedSyncOptions[key]);

  const isMockupDataChanged = (
    originalImages: any[],
    updatedImages: SelectedImage[],
  ): boolean => {
    if (originalImages.length !== updatedImages.length) {
      return true;
    }
    return !originalImages.every((image, index) => {
      const updatedImage = updatedImages[index];
      return (image.id ?? image.mockupId) === updatedImage.mockupId;
    });
  };

  const comparePublishingSettings = (
    originalSetting: PublishingSettings,
    newSetting: PublishingSettings,
  ): boolean =>
    originalSetting.showInStore === newSetting.showInStore &&
    compareSyncingOptions(
      originalSetting.syncingOptions,
      newSetting.syncingOptions,
    ) &&
    originalSetting.shippingProfile === newSetting.shippingProfile &&
    originalSetting.variantsVisibility === newSetting.variantsVisibility;

  const compareProductDetails = (original: any, changed: any): boolean => {
    if (!original || !changed) return false;

    const {
      description: originalDesc,
      title: originalTitle,
      tags: originalTags,
      variants: originalVariants,
      publishingSettings: originalPubSettings,
      personalizationInstructions: originalPersonalization,
    } = original;

    const {
      productDetails: {
        description: changedDesc,
        title: changedTitle,
        tags: changedTags,
        variants: changedVariants,
        publishingSettings: changedPubSettings,
      },
      personalizationInstructions: changedPersonalization,
    } = changed;

    const originalDescriptionText = extractDescriptionText(
      JSON.parse(originalDesc),
    );
    const changedDescriptionText = extractDescriptionText(changedDesc);

    return !(
      originalTitle === changedTitle &&
      _.isEqual(originalTags, changedTags) &&
      originalDescriptionText === changedDescriptionText &&
      _.isEqual(originalVariants, changedVariants) &&
      comparePublishingSettings(originalPubSettings, changedPubSettings) &&
      (originalPersonalization ?? '') === changedPersonalization
    );
  };
  const compareObjectArrays = (
    defaultObjects: any[],
    responseObjects: any[],
  ): boolean => {
    if (defaultObjects.length !== responseObjects.length) {
      return true;
    }

    return !defaultObjects.every((obj, index) => {
      // Destructuring to remove multiple keys
      const {
        src: _,
        originalWidth,
        originalHeight,
        originalImageBlobSrc,
        backgroundColor,
        ...objWithoutUnwantedKeys
      } = obj;
      const {
        src: __,
        originalWidth: ___,
        originalHeight: ____,
        originalImageBlobSrc: _____,
        backgroundColor: ______,
        ...responseObjWithoutUnwantedKeys
      } = responseObjects[index];
      const areEqual = isDataEqual(
        objWithoutUnwantedKeys,
        responseObjWithoutUnwantedKeys,
      );
      return areEqual;
    });
  };
  const compareObjectsInEditorData = (
    originalData: any,
    changedData: any,
  ): boolean => {
    if (!originalData || !changedData) return false;

    const { front: defaultFront, back: defaultBack } =
      changedData.defaultEditorData;
    const { front: responseFront, back: responseBack } =
      originalData.defaultEditorData;

    if (!responseFront && !responseBack) return false;

    const defaultFrontData =
      defaultFront !== null && defaultFront !== ''
        ? JSON.parse(defaultFront)
        : null;
    const defaultBackData =
      defaultBack !== null && defaultBack !== ''
        ? JSON.parse(defaultBack)
        : null;
    const responseFrontData =
      responseFront !== null && responseFront !== ''
        ? JSON.parse(responseFront)
        : null;
    const responseBackData =
      responseBack !== null && responseBack !== ''
        ? JSON.parse(responseBack)
        : null;

    if (
      defaultFrontData?.objects.length !== responseFrontData?.objects.length ||
      defaultBackData?.objects.length !== responseBackData?.objects.length
    ) {
      return true;
    }
    const hasFrontObjects =
      defaultFrontData?.objects && responseFrontData?.objects;
    const hasBackObjects =
      defaultBackData?.objects && responseBackData?.objects;

    const frontChanged =
      hasFrontObjects &&
      compareObjectArrays(defaultFrontData.objects, responseFrontData.objects);

    const backChanged =
      hasBackObjects &&
      compareObjectArrays(defaultBackData.objects, responseBackData.objects);

    return frontChanged || backChanged;
  };

  const variantDataChanged = (originalData: any, updatedData: any) => {
    if (
      !updatedData ||
      updatedData.variantEditorData.length !==
        originalData.variantEditorData.length ||
      updatedData.selectedColors.length !== updatedData.variantEditorData.length
    ) {
      return true;
    }

    return updatedData.variantEditorData.some(
      (
        variant: {
          variantSpecificDesign: boolean;
          front: string | null;
          back: string | null;
          id: string;
          label: string;
        },
        index: number,
      ) => {
        const responseVariant = originalData.variantEditorData[index];

        if (!responseVariant) return true;
        if (
          variant.variantSpecificDesign !==
          responseVariant.variantSpecificDesign
        ) {
          return true;
        }
        const updatedFrontData =
          variant.front !== null && variant.front !== ''
            ? JSON.parse(variant.front)
            : null;
        const updatedBackData =
          variant.back !== null && variant.back !== ''
            ? JSON.parse(variant.back)
            : null;
        const defaultFrontData =
          responseVariant.front !== null && responseVariant.front !== ''
            ? JSON.parse(responseVariant.front)
            : null;
        const defaultBackData =
          responseVariant.back !== null && responseVariant.back !== ''
            ? JSON.parse(responseVariant.back)
            : null;

        if (variant.variantSpecificDesign) {
          const frontChanged =
            variant.front && responseVariant.front
              ? compareObjectArrays(
                  updatedFrontData.objects,
                  defaultFrontData.objects,
                )
              : false;

          const backChanged =
            variant.back && responseVariant.back
              ? compareObjectArrays(
                  updatedBackData.objects,
                  defaultBackData.objects,
                )
              : false;

          return frontChanged || backChanged;
        }
        return variant.label !== responseVariant.label;
      },
    );
  };

  const runDataComparisons = useCallback(() => {
    let result = false;
    if (currentPage === 'details') {
      result = compareProductDetails(originalData, details);
    } else if (currentPage === 'editor') {
      if (
        originalData.defaultEditorData === '' &&
        updatedData.defaultEditorData.front == null &&
        updatedData.defaultEditorData.back == null
      )
        return false;
      result =
        compareObjectsInEditorData(originalData, updatedData) ||
        variantDataChanged(originalData, updatedData);
    } else if (currentPage === 'mockups') {
      result = isMockupDataChanged(originalData, selectedImages);
    }
    dispatch(setDataChanged(result));
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    originalData,
    details,
    updatedData,
    variantDataChanged,
    selectedImages,
  ]);
  useEffect(() => {
    runDataComparisons();
  }, [runDataComparisons]);
  return (
    <Section className="responsive-topbar">
      <FlexBox alignItems="center" gap="0.7rem">
        <span className="title-wrapper">{title}</span>
      </FlexBox>

      <FlexBox>
        <StepSystem
          linkCondition={catalogueId && productId}
          linkPath={`${routeNames.editor}/${catalogueId}/${printProviderId}/${productId}`}
          displayText="Editor"
          stepNumber="1"
          isStepDone={
            location.pathname.includes(routeNames.details) ||
            location.pathname.includes(routeNames.mockups)
          }
          isStepNumberActive={location.pathname.includes(routeNames.editor)}
        />
        <Separator />
        <StepSystem
          linkCondition={
            (productId && (modelNumber ?? catalogueId)) || isMockupsVisited
          }
          linkPath={`${routeNames.mockups}/${productId}/${
            modelNumber ?? getOrnamentsModel(catalogueId ?? '')
          }`}
          displayText="Images"
          stepNumber="2"
          isStepDone={location.pathname.includes(routeNames.details)}
          isStepNumberActive={location.pathname.includes(routeNames.mockups)}
        />
        <Separator />
        <StepSystem
          linkCondition={isDetailsVisited}
          linkPath={`${routeNames.details}/${productId}`}
          displayText="Details"
          stepNumber="3"
          isStepDone={false}
          isStepNumberActive={location.pathname.includes(routeNames.details)}
        />
      </FlexBox>
      {printProviderInfo.image ? (
        !isTablet ? (
          <FlexBox className="fs-14 print-provider-wrapper" gap="5px">
            <p className="fs-14 grey600">By:</p>
            <img
              src={printProviderInfo?.image}
              alt="print-provider"
              className="provider-image-wrapper "
            />
            <p className="fs-14 grey900">{printProviderInfo?.name} </p>
          </FlexBox>
        ) : (
          <CustomToolTip
            key={printProviderInfo?.name}
            title={printProviderInfo?.name}
          >
            <FlexBox gap="10px">
              <p>By:</p>
              <img
                src={printProviderInfo?.image}
                alt="print-provider"
                className="provider-image-wrapper"
              />
            </FlexBox>
          </CustomToolTip>
        )
      ) : null}
      <img
        src={Cross}
        alt="close"
        className="pointer-cursor"
        onClick={() =>
          runDataComparisons()
            ? setCancelModal(true)
            : !isDataSaved
            ? navigate('/')
            : setShowConfirmationModal(true)
        }
      />
      {cancelModal && (
        <TopBarCancelModal
          onSave={onSave}
          onClose={() => setCancelModal(false)}
        />
      )}
      {showConfirmationModal && (
        <ConfirmationModal onClose={() => setShowConfirmationModal(false)} />
      )}
    </Section>
  );
};

export default TopBar;

const Section = styled.div`
  display: flex;
  padding: 16px 32px;
  border-bottom: 1px solid ${colors.grey[300]};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  > div:first-child {
    display: flex;
    float: left;
  }
  > div > span:first-child {
    margin-right: 10px;
  }
  > div > * {
    margin: auto;
  }

  .bee-logo {
    cursor: pointer;
  }

  .confirm-modal {
    width: 460px !important;
    height: 140px !important;
  }

  .print-provider-wrapper{
    padding: 0 9px;
    border-left: 1px solid rgb(230, 230, 230);
    border-right: 1px solid rgb(230, 230, 230);
    height: 200%;
  }
  .provider-image-wrapper {
    height: 20px;
    objectFit: cover,
    background-blend-mode: darken;
  }
  .title-wrapper {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const Separator = styled.hr`
  width: 12px;
  border: 0.5px solid ${colors.grey[400]};
  height: 0px;
`;
