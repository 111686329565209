import { fabric } from 'fabric';
import { lastAddedElementIDByType } from '../components/editor/utils';
import { PersonalizationType } from '../interface';
import { ProductType } from '../interface/product-interface';

fabric.CustomText = fabric.util.createClass(fabric.IText, {
  type: 'custom-text',

  initialize: function (element, options) {
    this.callSuper('initialize', element, options);
    options &&
      this.set({
        id: options.id,
        personalizationData: options.personalizationData,
      });
  },

  toObject: function () {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      id: this.id,
      personalizationData: this.personalizationData,
    });
  },
});

fabric.CustomText.fromObject = function (object, callback) {
  return fabric.Object._fromObject('CustomText', object, callback, 'text');
};

export function addText(
  drawingBoard,
  files,
  isPersonalized = false,
  currentProductType,
) {
  let lastAddedId = lastAddedElementIDByType('custom-text', files);
  lastAddedId += 1;
  let text = new fabric.CustomText('Sample Text', {
    id: 'text-' + lastAddedId,
    objectCaching: false,
    fill: '#000000',
    originX: 'center',
    originY: 'center',
  });
  if (isPersonalized) {
    text.set({
      personalizationData: {
        isLocked: false,
        personalizationType: PersonalizationType.ALLOW_BUYERS_TO_UPLOAD,
        textOptions: {
          colors: [],
          fonts: [],
        },
      },
    });
  }
  text.set({
    left:
      currentProductType !== ProductType.ApparelHeavy &&
      currentProductType !== ProductType.ApparelLight &&
      currentProductType !== ProductType.ToteBag
        ? drawingBoard?.width / 2
        : drawingBoard.clipPath.left + text.getScaledWidth() / 2,
    top:
      currentProductType !== ProductType.ApparelHeavy &&
      currentProductType !== ProductType.ApparelLight &&
      currentProductType !== ProductType.ToteBag
        ? drawingBoard?.height / 2
        : drawingBoard.clipPath.top +
          text.getScaledHeight() / 2 +
          drawingBoard.clipPath.top / 2 +
          drawingBoard.clipPath.top,
  });
  if (
    currentProductType === ProductType.ApparelHeavy ||
    currentProductType === ProductType.ApparelLight ||
    currentProductType === ProductType.ToteBag
  ) {
    text.scaleToWidth(drawingBoard.clipPath.width);
  }
  drawingBoard?.add(text);
  if (text.personalizationData) {
    text.on('editing:entered', function () {
      text.set({
        backgroundColor: 'transparent',
      });
      drawingBoard.renderAll();
    });
  }
  drawingBoard?.setActiveObject(text);
  drawingBoard?.renderAll();
}
