import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import Modal from '../../../common/modal';
import MuiDataTable from '../../../table/MuiDataTable';
import { moreDetailsColumns } from './more-details-columns';
import { FlexBox } from '../../../common/wrappers';
import Button from '../../../common/button';
import { colors } from '../../../../assets/colors';
import Typography from '../../../typography';
import { SORTED_SIZES } from '../../../../constants/sizeCharts';
interface Props {
  onClose: () => void;
  groupedColorData: any[];
  groupedSizesData: any[];
}
const MoreDetailsModal: React.FC<Props> = ({
  onClose,
  groupedColorData,
  groupedSizesData,
}) => {
  const [groupByColors, setGroupByColors] = useState<boolean>(false);

  const addInventory = (data: any) => {
    if (
      data.totalInStock === data.totalStocks ||
      data.totalInStock > data.totalStocks
    ) {
      return 'All In Stock';
    } else {
      const inStock = data.totalStocks - data.totalInStock;
      return `${inStock} of ${data.totalStocks} out of stock`;
    }
  };

  const colorGroupedData = useMemo(() => {
    return groupedColorData.map((data) => {
      const minHobbyPrice = data.minPrice.hobby.toFixed(2);
      const maxHobbyPrice = data.maxPrice.hobby.toFixed(2);

      const minGrowthPrice = data.minPrice.growth.toFixed(2);
      const maxGrowthPrice = data.maxPrice.growth.toFixed(2);
      return {
        ...data,
        sizes: data.totalStocks,
        colors: data.color,
        inventory: addInventory(data),
        price:
          minHobbyPrice === maxHobbyPrice
            ? minHobbyPrice
            : `${minHobbyPrice} - ${maxHobbyPrice}`,
        growthPrice:
          minGrowthPrice === maxGrowthPrice
            ? minGrowthPrice
            : `${minGrowthPrice} - ${maxGrowthPrice}`,
      };
    });
  }, [groupedColorData]);

  const sizeGroupedData = useMemo(() => {
    const sortedData = [...groupedSizesData].sort((a, b) => {
      return SORTED_SIZES.indexOf(a.size) - SORTED_SIZES.indexOf(b.size);
    });
    return sortedData.map((data) => ({
      ...data,
      sizes: data.size,
      colors: data.totalStocks,
      inventory: addInventory(data),
      price: `${data.minPrice.hobby.toFixed(2)}`,
      growthPrice: `${data.minPrice.growth.toFixed(2)}`,
    }));
  }, [groupedSizesData]);
  return (
    <StyledWrapper>
      <Modal
        title={`Product Information`}
        onClose={onClose}
        className="more-details-modal"
      >
        <FlexBox direction="column" gap="5px" className="container">
          <Typography text="Group by:" fontSize="15px" />
          <FlexBox className="buttons-wrapper">
            <Button
              appearance="secondary"
              label="Size"
              onClick={() => setGroupByColors(false)}
              className={groupByColors ? '' : 'active'}
            />
            <Button
              appearance="secondary"
              label="Color"
              onClick={() => setGroupByColors(true)}
              className={groupByColors ? 'active' : ''}
            />
          </FlexBox>
        </FlexBox>
        <div className="table-wrapper">
          <MuiDataTable
            showCheckBox={false}
            columns={moreDetailsColumns(groupByColors)}
            rowData={groupByColors ? colorGroupedData : sizeGroupedData}
          />
        </div>
      </Modal>
    </StyledWrapper>
  );
};

export default MoreDetailsModal;

const StyledWrapper = styled.div`
  .more-details-modal {
    position: fixed;
    max-width: 700px;
    height: fit-content;
    width: 100%;
  }
  .container {
    padding-block: 1rem;
  }
  .buttons-wrapper {
    button {
      border-radius: 0;
      padding: 6px 12px;
      width: 9rem;
    }
    .active {
      background-color: ${colors.blue[900]};
      color: ${colors.white};
      border: none;
    }
  }
  .table-wrapper {
    height: 300px;
  }
`;
